import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {Subscription} from 'rxjs/internal/Subscription';

type buildingType = 'romney' | 'jawini' | 'variant' | 'variantplus';

@Component({
    selector: 'app-step-building-insets-component',
    templateUrl: './step-building-insets.component.html',
    styleUrls: ['./step-building-insets.component.scss']
})
export class StepBuildingInsetsComponent implements OnInit, OnDestroy {

    private configuratorEventListener: Subscription;
    public door: number;
    public window: number;

    public roofLightPanels: number = 0;
    public wallLightPanels: boolean = false;
    private gutterValue: boolean;
    private buildingType: buildingType;

    constructor(private configInterface: ConfiguratorInterfaceService) {
        this.configuratorEventListener = this.configInterface.receiveMessage().subscribe((data) => {
            if (data.action === 'stateChanged') {
                this.buildingType = data.buildingInfo.structureType;
                this.gutterValue = data.buildingInfo.gutter;
                this.roofLightPanels = data.template.roofLightPanels;
                this.wallLightPanels = data.template.wallLightPanels;
            }
        });
    }

    ngOnInit() {
        this.configInterface.viewerReady().then(() => {
            this.configInterface.requestState();
        });
    }

    ngOnDestroy() {
        this.configuratorEventListener.unsubscribe();
    }

    addDoor(number: number) {
        this.configInterface.sendMessage({
            action: 'addDoor',
            params: [number],
        });
    }

    addWindow(number: number) {
        this.configInterface.sendMessage({
            action: 'addWindow',
            params: [number],
        });
    }

    setWallLightPanels(boolean: boolean) {
        this.configInterface.sendMessage({
            action: 'setWallLightPanels',
            params: [boolean],
        });
    }

    changeTransparentSheet(number: number) {
        this.configInterface.sendMessage({
            action: 'setRoofLightPanels',
            params: [number],
        });
    }

    public setGutters(value: boolean) {
        this.gutterValue = value;
        this.configInterface.sendMessage({
            action: 'setGutters',
            params: [value],
        });
    }

    canHaveGutters(): boolean {
        return this.buildingType !== 'romney';
    }
}
