<app-sidebar-content *ngIf="measurements">
    <app-sidebar-property-component icon="arrownw" tooltip="configurator:step2:tooltip:depth">
        <app-input-number [direction]="'depth'" [value]="measurements.depth.value" [min]="5" [max]="100" [step]="2.5" (valueChanged)="depthChanged($event)"></app-input-number>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="arrowright" tooltip="configurator:step2:tooltip:width">
        <app-input-number [direction]="'width'" [value]="measurements.width.value" [min]="3" [max]="21" [step]="1" (valueChanged)="widthChanged($event)" [steps]="measurements.width.steps"></app-input-number>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="arrowup" tooltip="configurator:step2:tooltip:height">
        <app-input-button-dimension-toggle [currentValue]="measurements.height.value" [values]="measurements.height.steps" (valueChanged)="heightChanged($event)"></app-input-button-dimension-toggle>
    </app-sidebar-property-component>

    <app-sidebar-property-component icon="barnfrontwall" tooltip="configurator:step2:tooltip:remove">
        <app-input-checkbox [name]="'frontfacade'" [checked]="!!measurements.disableFrontFacade" [imagePath]="'assets/images/voorgevel.svg'" (valueChanged)="setFrontFacadeHidden($event)"></app-input-checkbox>
        <app-input-checkbox [name]="'backfacade'" [checked]="!!measurements.disableBackFacade" [imagePath]="'assets/images/achtergevel.svg'" (valueChanged)="setBackFacadeHidden($event)"></app-input-checkbox>
    </app-sidebar-property-component>

    <ng-container *ngIf="!measurements.disableFrontFacade">
        <app-sidebar-property-component icon="barnfrontwall" tooltip="configurator:step2:tooltip:frontOffset">
            <app-input-slider [steps]="measurements.frontOffset.steps" [value]="measurements.frontOffset.value" (valueChanged)="frontOffsetChanged($event)" unit="m" [forceSteps]="true"></app-input-slider>
        </app-sidebar-property-component>
    </ng-container>

    <app-sidebar-property-component icon="barninsidewall" tooltip="configurator:step2:tooltip:facadeInside:choice">
        <app-input-button-image-big (click)="hasFacadeInsideChanged('configurator:choice:no')" [class.active]="facadeInsideValue === 'configurator:choice:no'" [imagePath]="'assets/images/geen-tussenwand.svg'"></app-input-button-image-big>
        <app-input-button-image-big (click)="hasFacadeInsideChanged('configurator:choice:yes')" [class.active]="facadeInsideValue === 'configurator:choice:yes'" [imagePath]="'assets/images/tussenwand.svg'"></app-input-button-image-big>
    </app-sidebar-property-component>

    <ng-container *ngIf="facadeInsideValue === 'configurator:choice:yes'">
        <app-sidebar-property-component icon="barninsidewall" tooltip="configurator:step2:tooltip:facadeInside">
            <app-input-slider [steps]="measurements.facadeInside.steps" [value]="measurements.facadeInside.value" (valueChanged)="facadeInsideChanged($event)" unit="m"  [forceSteps]="true"></app-input-slider>
        </app-sidebar-property-component>
    </ng-container>

    <ng-container *ngIf="!measurements.disableBackFacade">
        <app-sidebar-property-component icon="barnbackwall" tooltip="configurator:step2:tooltip:backOffset">
            <app-input-slider [steps]="measurements.backOffset.steps" [value]="measurements.backOffset.value" (valueChanged)="backOffsetChanged($event)" unit="m"  [forceSteps]="true"></app-input-slider>
        </app-sidebar-property-component>
    </ng-container>

    <app-sidebar-property-component icon="barncanopy" tooltip="configurator:step2:tooltip:canopy">
        <app-input-checkbox [name]="'front'" [label]="'+ 1m'" [checked]="!!measurements.frontCanopy" [imagePath]="'assets/images/voorluifel.svg'" (valueChanged)="setFrontCanopy($event)"></app-input-checkbox>
        <app-input-checkbox [name]="'back'" [label]="'+ 1m'" [checked]="!!measurements.backCanopy" [imagePath]="'assets/images/achterluifel.svg'" (valueChanged)="setBackCanopy($event)"></app-input-checkbox>
    </app-sidebar-property-component>
</app-sidebar-content>
