import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';

@Component({
    selector: 'app-step-building-measurements-component',
    templateUrl: './step-building-measurements.component.html',
    styleUrls: ['./step-building-measurements.component.scss']
})
export class StepBuildingMeasurementsComponent implements OnInit, OnDestroy {
    configuratorEventListener: Subscription;
    public measurements;
    public facadeInsideValue = 'configurator:choice:no';

    public loaded = false;

    constructor(private configInterface: ConfiguratorInterfaceService) {
        this.configuratorEventListener = this.configInterface.receiveMessage().subscribe((data) => {

            if (!this.measurements) {
                this.measurements = {
                    height: {steps: [], value: 0},
                    width: {steps: [], value: 0},
                    depth: {steps: [], value: 0},
                    backOffset: {steps: [], value: 0},
                    facadeInside: {steps: [], value: 0},
                    frontOffset: {steps: [], value: 0},
                    backCanopy: {steps: [], value: 0},
                    frontCanopy: {steps: [], value: 0},
                    disableFrontFacade: {steps: [], value: 0},
                    disableBackFacade: {steps: [], value: 0}
                };
            }

            if (data.action === 'getMeasurements') {
                this.measurements.height.steps = data.height.values.map(( value ) => value.toString());
                this.measurements.height.value = data.height.currentValue.toString();
                this.measurements.width.steps = data.width.values.map(( value ) => value.toString());
                this.measurements.width.value = data.width.currentValue;
                this.measurements.depth.value = data.depth.currentValue;
                this.measurements.backOffset.steps = data.backOffset.values;
                this.measurements.backOffset.value = data.backOffset.currentValue;
                this.measurements.facadeInside.steps = data.facadeInside.values;
                this.measurements.facadeInside.value = data.facadeInside.currentValue;
                this.measurements.frontOffset.steps = data.frontOffset.values;
                this.measurements.frontOffset.value = data.frontOffset.currentValue;
                this.measurements.backCanopy = data.backCanopy.currentValue;
                this.measurements.frontCanopy = data.frontCanopy.currentValue;
                this.measurements.disableFrontFacade = data.disableFrontFacade.currentValue;
                this.measurements.disableBackFacade = data.disableBackFacade.currentValue;
            }

            if (data.action === 'stateChanged') {
                this.configInterface.sendMessage({action: 'getMeasurements', params: []});
            }

            this.facadeInsideValue = this.measurements.facadeInside.value > 0 ? 'configurator:choice:yes' : 'configurator:choice:no';
        });
    }

    ngOnInit() {
        this.configInterface.viewerReady().then(() => {
            this.configInterface.requestState();
        });
    }

    ngOnDestroy() {
        this.configuratorEventListener.unsubscribe();
    }

    public depthChanged(value: number) {
        this.measurements.depth.value = value;
        this.configInterface.sendMessage({
            action: 'changeDepth',
            params: [this.measurements.depth.value],
        });
    }

    public widthChanged(value: number) {
        this.measurements.width.value = this.findClosestValue(this.measurements.width.steps, value);
        this.configInterface.sendMessage({
            action: 'changeWidth',
            params: [this.measurements.width.value],
        });
    }

    public heightChanged(value: number) {
        this.configInterface.sendMessage({
            action: 'changeHeight',
            params: [value],
        });
    }

    public hasFacadeInsideChanged(value: string) {
        this.facadeInsideValue = value;
        const stepsValue = this.measurements.facadeInside.steps[this.measurements.facadeInside.value];
        const newValue = (value === 'configurator:choice:no' ? 0 : stepsValue);
        this.setFacadeInsideChanged(newValue);
    }

    public facadeInsideChanged(index: number) {
        this.setFacadeInsideChanged(this.measurements.facadeInside.steps[index]);
    }

    public setFacadeInsideChanged(value: number) {
        this.configInterface.sendMessage({
            action: 'setFacadeInside',
            params: [value],
        });
    }

    public frontOffsetChanged(value: number) {
        this.configInterface.sendMessage({
            action: 'setFrontOffset',
            params: [this.measurements.frontOffset.steps[value]],
        });
    }

    public backOffsetChanged(value: number) {
        this.configInterface.sendMessage({
            action: 'setBackOffset',
            params: [this.measurements.backOffset.steps[value]],
        });
    }

    public setFrontCanopy(value: boolean) {
        this.configInterface.sendMessage({
            action: 'setFrontCanopy',
            params: [value ? 1 : 0]
        });
    }

    public setBackCanopy(value: boolean) {
        this.configInterface.sendMessage({
            action: 'setBackCanopy',
            params: [value ? 1 : 0]
        });
    }

    public setFrontFacadeHidden(value: boolean) {
        this.configInterface.sendMessage({
            action: 'setFrontFacadeHidden',
            params: [value ? 1 : 0]
        });
    }

    public setBackFacadeHidden(value: boolean) {
        this.configInterface.sendMessage({
            action: 'setBackFacadeHidden',
            params: [value ? 1 : 0]
        });
    }

    private findClosestValue(array, value) {
        return array.reduce(function(prev, curr) {
            return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
        });
    }
}
