import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfiguratorInterfaceService} from '../../services/configurator-interface/configurator-interface.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {ColorService} from '../../services/color-service/color-service.service';

interface RalColourType {
    hex: string;
    ral: string;
}

@Component({
    selector: 'app-step-building-colouring-component',
    templateUrl: './step-building-colouring.component.html',
    styleUrls: ['./step-building-colouring.component.scss'],
})
export class StepBuildingColouringComponent implements OnInit, OnDestroy {

    private configuratorEventListener: Subscription;

    public colours: RalColourType[];
    public gutterColours: RalColourType[];

    public roof = '';
    public wall = '';
    public facade = '';
    public anglePiece = '';
    public frame = '';
    public gutter = '';

    public savedData = {
        roof: {hex: '', ral: ''},
        eastWall: {hex: '', ral: ''},
        westWall: {hex: '', ral: ''},
        facadeFront: {hex: '', ral: ''},
        facadeBack: {hex: '', ral: ''},
        anglePiece: {hex: '', ral: ''},
        gutter: {hex: '', ral: ''},
    };

    constructor(
        private configInterface: ConfiguratorInterfaceService,
        private colorService: ColorService,
    ) {
        this.configuratorEventListener = this.configInterface.receiveMessage()
            .subscribe((data) => {
                if (data.action === 'stateChanged') {
                    this.updateSavedData(data.addedData.colours);
                }
            });

        this.colours = [
            this.colorService.getColorForRal('RAL1015'),
            this.colorService.getColorForRal('RAL3009'),
            this.colorService.getColorForRal('RAL3016'),
            this.colorService.getColorForRal('RAL5010'),
            this.colorService.getColorForRal('RAL6009'),
            this.colorService.getColorForRal('RAL6011'),
            this.colorService.getColorForRal('RAL7016'),
            this.colorService.getColorForRal('RAL7035'),
            this.colorService.getColorForRal('RAL8014'),
            this.colorService.getColorForRal('RAL9002'),
            this.colorService.getColorForRal('RAL9006'),
            this.colorService.getColorForRal('RAL9005'),
            this.colorService.getColorForRal('RAL9007'),
        ];

        this.gutterColours = [
            this.colorService.getColorForRal('RAL7016'),
            this.colorService.getColorForRal('RAL7035'),
            this.colorService.getColorForRal('RAL9006'),
        ];
    }

    ngOnInit() {
        this.configInterface.viewerReady().then(() => {
            this.configInterface.requestState();
        });
    }

    ngOnDestroy() {
        this.configuratorEventListener.unsubscribe();
    }

    updateSavedData(colours: any) {
        this.savedData = colours;
        Object.keys(colours).forEach((materialName) => {
            if (materialName === 'eastWall') {
                this.wall = colours[materialName].ral;
            } else if (materialName === 'facadeFront') {
                this.facade = colours[materialName].ral;
            } else {
                this[materialName] = colours[materialName].ral;
            }
        });
    }

    changeRoofColour(colour: {hex: string, ral: string}) {
        this.roof = colour.ral;
        this.changeColour('changeColorMaterial', colour.hex, colour.ral,  'roof');
    }

    changeWallColour(colour: {hex: string, ral: string}) {
        this.wall = colour.ral;
        this.changeColour('changeColorMaterial', colour.hex, colour.ral, 'eastWall');
        this.changeColour('changeColorMaterial', colour.hex, colour.ral, 'westWall');
    }

    changeFacadeColour(colour: {hex: string, ral: string}) {
        this.facade = colour.ral;
        this.changeColour('changeColorMaterial', colour.hex, colour.ral, 'facadeFront');
        this.changeColour('changeColorMaterial', colour.hex, colour.ral, 'facadeBack');
    }

    changeanglePieceColour(colour: {hex: string, ral: string}) {
        this.anglePiece = colour.ral;
        this.changeColour('changeColorMaterial', colour.hex, colour.ral, 'anglePiece');
    }

    changegutterColour(colour: {hex: string, ral: string}) {
        this.gutter = colour.ral;
        this.changeColour('changeColorMaterial', colour.hex, colour.ral, 'gutter');
    }

    private changeColour(action: string, colour: string, colourName: string, name: string) {
        this.configInterface.sendMessage({
            action,
            params: [name, colour, colourName],
        });
    }

}
